export const breakpoints = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "1350px",
  xl: "1500px",
}

export const palette = {
  primary: "#1d436e",
  primaryContrast: "#f5f5f5",
  primaryContrastStong: "#fff",
  primaryContrastLight: "#c7c7c7",

  dark: "#232531",
  darkContrast: "#818181",

  secondary: "#5ab2d2",
  secondaryLight: "#819ba8",
}
