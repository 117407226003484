/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Footer from "./footer"
import { Helmet } from "react-helmet"

import "@fontsource/nunito"
import "./layout.css"
//import LiveChat from "./live-chat/livechat"

import Navbar from "./navbar/navbar"

const Layout = props => {
  return (
    <div>
      <Helmet>
        <script
          defer
          src="https://unpkg.com/@tinybirdco/flock.js"
          data-host="https://api.tinybird.co"
          data-token="p.eyJ1IjogIjU5NzNlZDMzLTEzZjgtNDRkNi04NGU4LWNiZDQ4ZDhjNjEyMSIsICJpZCI6ICJkMGJhM2ZhMS1jYmY2LTQ1MWItOWE4Ny0xYmZiN2ZlYWQ4OWQifQ.xLg-Boe8diqImNNpXHkUbhJESTWaCmToDQNdtm98_FI"
        ></script>
      </Helmet>
      <Navbar firmName={props.firmName} firmWeb={props.firmWeb} />
      <main>{props.children}</main>
      <Footer firmName={props.firmName}></Footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
