import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette } from "../../_variables"
import { respondTo } from "../../_respondTo"
import { FiLock } from "react-icons/fi"

// used to make child routes show as active
const partlyActive = className => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ` active` : ``),
})

const PartlyActiveLink = ({ className, ...rest }) => (
  <Link getProps={partlyActive(className)} {...rest} />
)
//end

const NavItem = styled(PartlyActiveLink)`
  text-decoration: none;
  color: ${palette.primaryContrastStong};
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  transition: all 200ms ease-in;
  position: relative;
  z-index: 6;
  text-align: center;
  width: 80%;
  border-bottom: thin solid;

  text-transform: uppercase;
  font-size: medium;

  padding: 0.1em 1em;

  :hover {
    ::after {
      width: 100%;
    }
  }

  &.active {
    border-color: ${palette.primary};
  }

  hr {
    color: ${palette.primaryContrast};
  }

  ${respondTo.sm`
  font-size:x-small;
  
  `}

  ${respondTo.lg`
  font-size:medium;
  
  `}

  ${respondTo.md`
    color: ${palette.primary};
    border-radius: 2em;
    border-style: solid;
    border-color: white;
    border-width: thin;
  


    :hover{
      border-color: ${palette.primary};
    }
    
  `}


  &.self-serve {
    background-color: ${palette.primaryContrast};
    color: ${palette.primary};
    border-radius: 2em;
    padding: 0 1em;

    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.secondaryLight};
      text-decoration: none;
    }

    ${respondTo.md`
    background-color: ${palette.secondary};
    color: ${palette.primaryContrast};
    `}
  }

  &.pfp {
    background-color: ${palette.primaryContrast};
    color: ${palette.primary};
    border-radius: 2em;
    padding: 0 1em;

    :hover {
      color: ${palette.primaryContrastStong};
      background-color: ${palette.secondaryLight};
      text-decoration: none;
    }

    ${respondTo.md`
    background-color: ${palette.primary};
    color: ${palette.primaryContrast};
    `}
  }
`

const NavLogo = styled(Link)`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin: 0;
  margin: 0 1em;
  text-align: center;
`

const NavbarLinks = props => {
  return (
    <>
      <NavItem to={props.firmName + "/about-you"} activeClassName="active">
        About You
      </NavItem>
      <NavItem to={props.firmName + "/who-are-we"} activeClassName="active">
        Who Are We
      </NavItem>
      <NavItem to={props.firmName + "/our-services"} activeClassName="active">
        Our Services
      </NavItem>
      <NavItem
        to={props.firmName + "/finding-true-wealth"}
        activeClassName="active"
      >
        Blog
      </NavItem>
      <NavItem to={props.firmName + "/contact-us"} activeClassName="active">
        Contact Us
      </NavItem>

      <NavItem as="a" href="https://tag.gb.pfp.net/" className="pfp">
        <FiLock style={{ marginRight: "0.1em", paddingTop: "0.1em" }} />
        Sign In
      </NavItem>

      {props.firmName !== "" && (
        <NavLogo
          as="a"
          href={props.firmWeb}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="color"
            src={
              "/firm_logos" +
              props.firmName +
              (props.open ? "" : "-white") +
              ".png"
            }
            style={{
              margin: "auto",
              maxWidth: "10ch",
            }}
          ></img>
        </NavLogo>
      )}
    </>
  )
}

export default NavbarLinks
