import React from "react"
import styled from "styled-components"

import { respondTo } from "../_respondTo"

const Wrapper = styled.div`
  padding: 2em 1.5em;
  margin: 0 auto;
  
  ${respondTo.md`
    margin: 0 20vw;
    
    &.wide{
      margin: 0 5vw;
    }

  `}
`

function Section(props) {
  return <Wrapper className={props.type}>{props.children}</Wrapper>
}

export default Section
