import React, { useState } from "react"
import styled from "styled-components"
import NavbarLinks from "./navbarLinks"
import Logo from "./logo"
import { palette } from "../../_variables"
import { respondTo } from "../../_respondTo"

const Navigation = styled.nav`
  height: 8vh;
  min-height: 70px;
  display: flex;
  background-color: ${palette.primary};

  position: fixed;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 0 0 5vw;
  z-index: 999;
  align-self: center;
  top: 0;
  left: 0;
  right: 0;
  left: 0;

  align-items: center;

  ${respondTo.md`
    background-color: white;
    padding: 0 2vw;

  `}
`

const NavBuffer = styled.div`
  height: max(8vh, 70px);
`

const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  padding: 0 15px;

  ${respondTo.md`
  display: none;

  `}
`

const Navbox = styled.div`
  display: flex;
  //flex: 1;
  height: 92vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  top: 8vh;
  z-index: -1;

  flex-direction: column;
  position: fixed;
  padding-top: 10vh;
  padding-bottom: 10vh;

 // background-color: ${palette.primary};

  background-image: linear-gradient( to bottom, ${palette.primary}, ${
  palette.secondary
});

  transition: all 0.3s ease-in;
  right: ${props => (props.open ? "-100%" : "0")};
  font-size: 1.2em;

  ${respondTo.md`
    top:0;
    background-image: none;
    background-color: white;
    height: 100%;
    font-size: 1em;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
    justify-content: flex-end;
    position: static;

    font-size: small;
  `}

  ${respondTo.lg`
    font-size: medium;
  `}
`

const Hamburger = styled.div`
  background-color: ${palette.primaryContrast};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${palette.primaryContrast};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Navbar = props => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <div>
      <NavBuffer></NavBuffer>

      <Navigation>
        <Logo firmName={props.firmName} />
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
        {navbarOpen ? (
          <Navbox>
            <NavbarLinks firmName={props.firmName} firmWeb={props.firmWeb} />
          </Navbox>
        ) : (
          <Navbox open>
            <NavbarLinks
              firmName={props.firmName}
              firmWeb={props.firmWeb}
              open
            />
          </Navbox>
        )}
      </Navigation>
    </div>
  )
}

export default Navbar
