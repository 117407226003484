import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import logo from "../../images/tag-financial-planning-logo.svg"
import logoMobileTagOnly from "../../images/tag-financial-planning-logo-white.svg"
import logoMobileFirmAlso from "../../images/tag-financial-planning-logo-mobile.svg"
import { respondTo } from "../../_respondTo"

const LogoWrap = styled.div`
  margin: 0;
  width: 43vh; //(643 / 90) * 6vh - ratio of logo * desired height
  min-width: 124px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.firmAlso {
    width: 23vh; //(400 / 105) * 6vh - ratio of logo * desired height

    max-width: 50%;
  }

  ${respondTo.md`
    
    &.firmAlso{
    width: 43vh; //(643 / 90) * 6vh - ratio of logo * desired height
    min-width: 124px;
  }

  `}
`

const LogoImg = styled.img`
  &.full {
    display: none;
  }

  &.hide {
    display: none;
  }

  ${respondTo.md`

      &.full{
        display: inherit;
      }

      &.mobile{
        display:none;
      }


    `}
`

const FirmImg = styled.img`
  max-width: 30vw;
  max-height: 6vh;

  margin: auto;
  vertical-align: middle;
  display: inline-block;
  //margin-left: 3vw;
  position: absolute;
  right: 60px;

  ${respondTo.md`
        display: none;
    `}

  &.hide {
    display: none;
  }
`

const Logo = props => {
  return (
    <LogoWrap
      as={Link}
      to={"" + (props.firmName == "" ? "/" : "" + props.firmName)}
      className={props.firmName == "" ? "" : "firmAlso"}
    >
      <LogoImg
        className="full"
        src={logo}
        alt="TAG Financial Planning logo"
        style={{ margin: "1vh 0" }}
      ></LogoImg>

      <LogoImg
        className={props.firmName == "" ? "mobile" : "hide"}
        src={logoMobileTagOnly}
        alt="TAG Financial Planning logo"
        style={{ margin: "1vh 0" }}
      ></LogoImg>

      <LogoImg
        className={props.firmName == "" ? "hide" : "mobile"}
        src={logoMobileFirmAlso}
        alt="TAG Financial Planning logo"
        style={{ margin: "1vh 0" }}
      ></LogoImg>
      <FirmImg
        className={props.firmName == "" ? "hide" : ""}
        src={"/firm_logos" + props.firmName + "-white.png"}
      ></FirmImg>
    </LogoWrap>
  )
}

export default Logo
